import React, {useRef} from 'react';

const VideoPlayer =(props)=>  {
    const {vidSrc, plcHldr, id} = props;
    const vidRef = useRef(null);

    return (
        <video controls
               preload="auto"
               width="100%"
               poster={plcHldr}
               ref={vidRef}
               id={id}>
            <source src={vidSrc} type="video/mp4" />
            {'Your browser does not support the video tag.'}
        </video>
    );
};

export default VideoPlayer;
