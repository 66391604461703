export const TRANSLATOR_TARGET_TWO_MX_MESSAGES = {
    "pacs.008.001.08": {
        "title": "pacs.008.001.08",
        "conversion": "MT103",
        "msg": `<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08">
    <FIToFICstmrCdtTrf>
        <GrpHdr>
            <MsgId>5387354</MsgId>
            <CreDtTm>2020-06-22T13:38:50.889+03:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>CLRG</SttlmMtd>
                <ClrSys>
                    <Cd>TGT</Cd>
                </ClrSys>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>ONLYMANDATORY</InstrId>
                <EndToEndId>NOTPROVIDED</EndToEndId>
                <UETR>00000000-0000-4000-8000-000000000000</UETR>
            </PmtId>
            <IntrBkSttlmAmt Ccy="USD">1101.50</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2012-09-29</IntrBkSttlmDt>
            <ChrgBr>DEBT</ChrgBr>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33XXX</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>AAAAGB2LXXX</BICFI>
                </FinInstnId>
            </InstdAgt>
            <Dbtr>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICAXXX</AnyBIC>
                    </OrgId>
                </Id>
            </Dbtr>
            <DbtrAgt>
                <FinInstnId>
                    <BICFI>TESTBICAXXX</BICFI>
                </FinInstnId>
            </DbtrAgt>
            <CdtrAgt>
                <FinInstnId>
                    <BICFI>TESTBICBXXX</BICFI>
                </FinInstnId>
            </CdtrAgt>
            <Cdtr>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICBXXX</AnyBIC>
                    </OrgId>
                </Id>
            </Cdtr>
        </CdtTrfTxInf>
    </FIToFICstmrCdtTrf>
</Document>
`},
    "pacs.009.001.08.core": {
        "title": "pacs.009.001.08 CORE",
        "conversion": "MT202",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08">
    <FICdtTrf>
        <GrpHdr>
            <MsgId>Inp009b028-InsId</MsgId>
            <CreDtTm>2021-03-23T13:42:58.502+02:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>CLRG</SttlmMtd>
                <ClrSys>
                    <Cd>TGT</Cd>
                </ClrSys>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>Inp009b028-InsId</InstrId>
                <EndToEndId>NOTPROVIDED</EndToEndId>
                <UETR>e008b028-59c5-41e9-be4c-d45102fc201e</UETR>
            </PmtId>
            <IntrBkSttlmAmt Ccy="EUR">61250.00</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2019-10-07</IntrBkSttlmDt>
            <SttlmTmIndctn>
                <CdtDtTm>2019-10-07T09:15:00.000+02:00</CdtDtTm>
            </SttlmTmIndctn>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>PBBBDEFFXXX</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>PBAADEFFC2X</BICFI>
                </FinInstnId>
            </InstdAgt>
            <Dbtr>
                <FinInstnId>
                    <BICFI>PBBBDEFFXXX</BICFI>
                </FinInstnId>
            </Dbtr>
            <Cdtr>
                <FinInstnId>
                    <BICFI>PBAADEFFAC2</BICFI>
                </FinInstnId>
            </Cdtr>
        </CdtTrfTxInf>
    </FICdtTrf>
</Document>` },
    "pacs.009.001.08.cov": {
            "title": "pacs.009.001.08 COV",
            "conversion": "MT202 COV",
            "msg": `<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08">
    <FICdtTrf>
        <GrpHdr>
            <MsgId>BBBB/120928-FICT/JPY/430</MsgId>
            <CreDtTm>2012-09-28T16:00:00+13:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>CLRG</SttlmMtd>
                <ClrSys>
                    <Cd>TGT</Cd>
                </ClrSys>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>BBBB/120928-FICT</InstrId>
                <EndToEndId>ABC/4562/2012-09-08</EndToEndId>
                <UETR>00000000-0000-4000-8000-000000000000</UETR>
            </PmtId>
            <IntrBkSttlmAmt Ccy="JPY">10000000</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2012-09-29</IntrBkSttlmDt>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33XXX</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>CCCCJPJTXXX</BICFI>
                </FinInstnId>
            </InstdAgt>
            <IntrmyAgt1>
                <FinInstnId>
                    <Nm>INTER AGENT</Nm>
                    <PstlAdr>
                        <AdrLine>ADDRESS 1</AdrLine>
                        <AdrLine>ADDRESS 2</AdrLine>
                    </PstlAdr>
                </FinInstnId>
            </IntrmyAgt1>
            <Dbtr>
                <FinInstnId>
                    <Nm>DEBTOR NAME</Nm>
                    <PstlAdr>
                        <AdrLine>ADDRESS 1</AdrLine>
                        <AdrLine>ADDRESS 2</AdrLine>
                    </PstlAdr>
                </FinInstnId>
            </Dbtr>
            <CdtrAgt>
                <FinInstnId>
                    <Nm>CDTR AGT NAME</Nm>
                    <PstlAdr>
                        <AdrLine>ADDRESS 1</AdrLine>
                        <AdrLine>ADDRESS 2</AdrLine>
                    </PstlAdr>
                </FinInstnId>
            </CdtrAgt>
            <Cdtr>
                <FinInstnId>
                    <Nm>CDTR NAME</Nm>
                    <PstlAdr>
                        <AdrLine>ADDRESS 1</AdrLine>
                        <AdrLine>ADDRESS 2</AdrLine>
                    </PstlAdr>
                </FinInstnId>
            </Cdtr>
            <UndrlygCstmrCdtTrf>
                <InitgPty>
                    <Nm>ABC Corporation</Nm>
                    <PstlAdr>
                        <StrtNm>Times Square</StrtNm>
                        <BldgNb>7</BldgNb>
                        <PstCd>NY 10036</PstCd>
                        <TwnNm>New York</TwnNm>
                        <Ctry>US</Ctry>
                    </PstlAdr>
                </InitgPty>
                <Dbtr>
                    <Nm>ABC Corporation</Nm>
                    <PstlAdr>
                        <AdrLine>Address</AdrLine>
                    </PstlAdr>
                </Dbtr>
                <DbtrAcct>
                    <Id>
                        <Othr>
                            <Id>00125574999</Id>
                        </Othr>
                    </Id>
                </DbtrAcct>
                <DbtrAgt>
                    <FinInstnId>
                        <Nm>DEBTOR AGENT</Nm>
                        <PstlAdr>
                            <AdrLine>ADDRESS 1</AdrLine>
                            <AdrLine>ADDRESS 2</AdrLine>
                        </PstlAdr>
                    </FinInstnId>
                </DbtrAgt>
                <IntrmyAgt1>
                    <FinInstnId>
                        <Nm>INTER AGENT</Nm>
                        <PstlAdr>
                            <AdrLine>ADDRESS 1</AdrLine>
                            <AdrLine>ADDRESS 2</AdrLine>
                        </PstlAdr>
                    </FinInstnId>
                </IntrmyAgt1>
                <CdtrAgt>
                    <FinInstnId>
                        <Nm>CREDITOR AGENT</Nm>
                        <PstlAdr>
                            <AdrLine>ADDRESS 1</AdrLine>
                            <AdrLine>ADDRESS 2</AdrLine>
                        </PstlAdr>
                    </FinInstnId>
                </CdtrAgt>
                <Cdtr>
                    <Nm>DEF Electronics</Nm>
                    <PstlAdr>
                        <AdrLine>Address</AdrLine>
                    </PstlAdr>
                </Cdtr>
                <CdtrAcct>
                    <Id>
                        <Othr>
                            <Id>23683707994215</Id>
                        </Othr>
                    </Id>
                </CdtrAcct>
                <RmtInf>
                    <Strd>
                        <RfrdDocInf>
                            <Tp>
                                <CdOrPrtry>
                                    <Cd>CINV</Cd>
                                </CdOrPrtry>
                            </Tp>
                            <Nb>4562</Nb>
                            <RltdDt>2012-09-08</RltdDt>
                        </RfrdDocInf>
                    </Strd>
                </RmtInf>
            </UndrlygCstmrCdtTrf>
        </CdtTrfTxInf>
    </FICdtTrf>
</Document>`},
"camt.054.001.08": {
"title": "camt.054.001.08",
"conversion": "MT900",
"msg": `<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.054.001.08">
    <BkToCstmrDbtCdtNtfctn>
        <GrpHdr>
            <MsgId>AAAASESS-FP-00001</MsgId>
            <CreDtTm>2015-10-18T13:20:00+01:00</CreDtTm>
        </GrpHdr>
        <Ntfctn>
            <Id>AAAASESS-FP-CN-98765</Id>
            <Acct>
                <Id>
                    <Othr>
                        <Id>50000000054910000003</Id>
                    </Othr>
                </Id>
            </Acct>
            <Ntry>
                <NtryRef>AAAASESS-FP-CN-9</NtryRef>
                <Amt Ccy="SEK">105678.50</Amt>
                <CdtDbtInd>DBIT</CdtDbtInd>
                <Sts>
                    <Cd>BOOK</Cd>
                </Sts>
                <BookgDt>
                    <DtTm>2015-10-18T13:15:00+01:00</DtTm>
                </BookgDt>
                <ValDt>
                    <Dt>2015-10-18</Dt>
                </ValDt>
                <BkTxCd>
                    <Prtry>
                        <Cd>PMNT</Cd>
                    </Prtry>
                </BkTxCd>
                <NtryDtls>
                    <TxDtls>
                        <Refs>
                            <EndToEndId>MUELL/FINP/RA123</EndToEndId>
                            <UETR>c8b66b47-2bd9-48fe-be90-93c2096f27d2</UETR>
                        </Refs>
                        <Amt Ccy="SEK">0</Amt>
                        <RltdPties>
                            <Dbtr>
                                <Pty>
                                    <Nm>ABC Corporation</Nm>
                                    <Id>
                                        <OrgId>
                                            <AnyBIC>TESTBICAXXX</AnyBIC>
                                        </OrgId>
                                    </Id>
                                </Pty>
                            </Dbtr>
                            <DbtrAcct>
                                <Id>
                                    <Othr>
                                        <Id>00125574999</Id>
                                    </Othr>
                                </Id>
                            </DbtrAcct>
                        </RltdPties>
                        <RltdAgts>
                            <DbtrAgt>
                                <FinInstnId>
                                    <BICFI>BBBBUS33XXX</BICFI>
                                </FinInstnId>
                            </DbtrAgt>
                        </RltdAgts>
                    </TxDtls>
                </NtryDtls>
            </Ntry>
        </Ntfctn>
    </BkToCstmrDbtCdtNtfctn>
</Document>`},
    "camt.053.001.08": {
        "title": "camt.053.001.08",
        "conversion": "MT940",
        "msg": `<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.053.001.08">
    <BkToCstmrStmt>
        <GrpHdr>
            <MsgId>AAAASESS-FP-STAT001</MsgId>
            <CreDtTm>2015-10-18T17:00:00+01:00</CreDtTm>
            <MsgPgntn>
                <PgNb>1</PgNb>
                <LastPgInd>true</LastPgInd>
            </MsgPgntn>
        </GrpHdr>
        <Stmt>
            <Id>AAAASESS-FP-STAT001</Id>
            <Acct>
                <Id>
                    <Othr>
                        <Id>50000000054910000003</Id>
                    </Othr>
                </Id>
                <Ownr>
                    <Id>
                        <OrgId>
                            <AnyBIC>TESTBICAXXX</AnyBIC>
                        </OrgId>
                    </Id>
                </Ownr>
            </Acct>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>OPBD</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">500000</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-15</Dt>
                </Dt>
            </Bal>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>CLBD</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">435678.50</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-18</Dt>
                </Dt>
            </Bal>
            <Ntry>
                <NtryRef>Entry reference</NtryRef>
                <Amt Ccy="SEK">105678.50</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Sts>
                    <Cd>BOOK</Cd>
                </Sts>
                <BookgDt>
                    <DtTm>2015-10-17T13:15:00+01:00</DtTm>
                </BookgDt>
                <ValDt>
                    <Dt>2015-10-18</Dt>
                </ValDt>
                <BkTxCd>
                    <Prtry>
                        <Cd>ASTI</Cd>
                    </Prtry>
                </BkTxCd>
                <NtryDtls>
                    <TxDtls>
                        <Refs>
                            <InstrId>InstrId</InstrId>
                            <EndToEndId>MUELL/FINP/RA12345</EndToEndId>
                        </Refs>
                        <Amt Ccy="SEK">0</Amt>
                        <RltdPties>
                            <Dbtr>
                                <Pty>
                                    <Nm>MUELLER</Nm>
                                </Pty>
                            </Dbtr>
                        </RltdPties>
                    </TxDtls>
                </NtryDtls>
            </Ntry>
        </Stmt>
    </BkToCstmrStmt>
</Document>`},
"pacs.004.001.09": {
    "title": "pacs.004.001.09",
    "conversion": "MT202 (Return)",
    "msg": `<?xml version="1.0" encoding="UTF-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.004.001.09">
    <PmtRtr>
        <GrpHdr>
            <MsgId>CCCC/151122-PR007</MsgId>
            <CreDtTm>2015-11-22T10:37:00+03:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>CLRG</SttlmMtd>
                <ClrSys>
                    <Cd>TGT</Cd>
                </ClrSys>
            </SttlmInf>
        </GrpHdr>
        <TxInf>
            <RtrId>CCCC/151122-PR007</RtrId>
            <OrgnlGrpInf>
                <OrgnlMsgId>BBBB/151109-CBJ056</OrgnlMsgId>
                <OrgnlMsgNmId>pacs.009.001.08</OrgnlMsgNmId>
                <OrgnlCreDtTm>2015-11-09T10:13:00+03:00</OrgnlCreDtTm>
            </OrgnlGrpInf>
            <OrgnlInstrId>BBBB/151109-CBJ0</OrgnlInstrId>
            <OrgnlEndToEndId>CROPS/SX-25T/2015-10-13</OrgnlEndToEndId>
            <OrgnlUETR>00000000-0000-4000-8000-000000000000</OrgnlUETR>
            <OrgnlIntrBkSttlmAmt Ccy="EUR">74900</OrgnlIntrBkSttlmAmt>
            <OrgnlIntrBkSttlmDt>2015-11-09</OrgnlIntrBkSttlmDt>
            <RtrdIntrBkSttlmAmt Ccy="EUR">74750</RtrdIntrBkSttlmAmt>
            <IntrBkSttlmDt>2015-11-22</IntrBkSttlmDt>
            <SttlmTmIndctn>
                <CdtDtTm>2012-09-28T16:00:00+13:00</CdtDtTm>
            </SttlmTmIndctn>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>CCCCIE2DXXX</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>BBBBIE2DXXX</BICFI>
                </FinInstnId>
            </InstdAgt>
            <RtrChain>
                <Dbtr>
                    <Agt>
                        <FinInstnId>
                            <BICFI>TESTBICAXXX</BICFI>
                        </FinInstnId>
                    </Agt>
                </Dbtr>
                <Cdtr>
                    <Agt>
                        <FinInstnId>
                            <BICFI>TESTBICBXXX</BICFI>
                        </FinInstnId>
                    </Agt>
                </Cdtr>
            </RtrChain>
            <RtrRsnInf>
                <Rsn>
                    <Cd>NARR</Cd>
                </Rsn>
                <AddtlInf>RETURN AFTER ACCEPTED PAYMENT CANCELLATION REQUEST</AddtlInf>
            </RtrRsnInf>
        </TxInf>
    </PmtRtr>
</Document>`}
}