import React from 'react';
import {Footer} from './Footer';

const FooterLazyHelper =()=>  {
    return (
        <React.Fragment>
            <Footer />
        </React.Fragment>
    );
};

export default FooterLazyHelper;