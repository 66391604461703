export const TRANSLATOR_TARGET_TWO_MT_MESSAGES = {
    "103": {
    "title": "MT103",
    "conversion": "pacs.008",
    "msg": `{1:F01AAAAGRA0AXXX0057000289}{2:O1030919010321BBBBGRA0AXXX00570001710103210920N}{3:{108:MT103 003 OF 045}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:5387354
:23B:CRED
:32A:000526USD1101,50
:33B:USD1121,50
:50A:/ACCOUNTID
TESTBICA
:59A:TESTBICB
:71A:OUR
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "103return": {
        "title": "MT103 (Return)",
        "conversion": "pacs.004",
        "msg": `{1:F01AAAAAAA0AXXX0057000289}{2:O1030919010321BBBBGRA0AXXX00570001710103210920N}{3:{108:MT103 003 OF 045}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:5387354
:23B:CRED
:32A:000526USD1101,50
:50A:/ACCOUNTID
TESTBICA
:59A:TESTBICB
:71A:OUR
:72:/RETN/32
/AC01/
/MREF/123456789
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`},
    "202": {
        "title": "MT202",
        "conversion": "pacs.009",
        "msg": `{1:F01PBAADEFFAC2X0000000000}{2:O2021109210323PBBBDEFFXXXX00000000002103231109N}{3:{121:e008b028-59c5-41e9-be4c-d45102fc201e}}{4:
:20:Inp009b028-InsId
:21:Inp008b028-E2EId
:13C:/RNCTIME/0915+0200
:32A:191007EUR61250,00
:52A:PBBBDEFFXXX
:58A:PBAADEFFAC2
-}`
    },
    "202cov": {
        "title": "MT202 COV",
        "conversion": "pacs.009",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I202CCCCUS33AXXXN}{3:{119:COV}{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987COV
:21:090525/123COV
:13C:/RNCTIME/1249+0200
:32A:090527USD10500,00
:58A:TESTBICF
:50K:GEROGE
ADRESS LINE 1
ADRESS LINE 2
:59F:/BE30001216371411
1/PHILIPS MARK
2/3658 WITMER ROAD
3/BE/BRUSSELS
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`
    },
    "202return": {
        "title": "MT202 (Return)",
        "conversion": "pacs.004",
        "msg": `{1:F01AAAABEBBAXXX0000000000}{2:I202CCCCUS33AXXXN}{3:{121:c8b66b47-2bd9-48fe-be90-93c2096f27d2}}{4:
:20:987
:21:090525/123COV
:32A:090527USD10500,00
:58A:BBBBGB22
:72:/RETN/32
/AC01/
/MREF/123456789
-}{5:{MAC:75D138E4}{CHK:DE1B0D71FA96}}`}
}

