import React, {Suspense} from 'react';
import {SUBHEADER_VALUES} from "../../../lib/constants";
import screenshot from './../../../../assets/img/finaplo_screenshot.png';
import poster1 from './../../../../assets/img/poster_1.png';
import poster2 from './../../../../assets/img/poster_2.png';
import video1 from './../../../../assets/video/how_to_translate.mp4';
import video2 from './../../../../assets/video/how_to_validate.mp4';
const VideoPlayer  =  React.lazy(() => import('./VideoPlayer'));

const CheckerMobile =()=>  {

    return (
        <section className='checker__container--mobile'>
            <article className='checker__container--mobile__web-prompt'>
                <hgroup>
                    <h1>
                        {SUBHEADER_VALUES.checker.title}
                    </h1>
                    <h2>
                        {SUBHEADER_VALUES.checker.description}
                    </h2>
                </hgroup>
                <div>
                    <p>
                        <span>
                            {'For a Free Online Trial visit FINaplo Financial Services through your web browser'}
                        </span>
                    </p>
                    <img src={screenshot} alt="a schreenshot of FINaplo Financial Services" />
                </div>
            </article>
            <article className='checker__container--mobile__videos'>
                <div className='video'>
                    <h3>{'How to Translate your MT103 to pacs 008 with one click (0:15)'}</h3>
                    <Suspense fallback={<div style={{width: "255px", height: "144px"}}></div>}>
                        <VideoPlayer vidSrc={video1} plcHldr={poster1} id={"video1"} key={"video1"}/>
                    </Suspense>
                </div>
                <div className='video'>
                    <h3>{'How to validate a TARGET2 message οr any other ISO20022 message (1:41)'}</h3>
                    <Suspense fallback={<div style={{width: "255px", height: "144px"}}></div>}>
                        <VideoPlayer vidSrc={video2} plcHldr={poster2}  id={"video2"} key={"video2"}/>
                    </Suspense>
                </div>
            </article>
        </section>

    );
};

export default CheckerMobile;
