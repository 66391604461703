import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {FOOTER_LINKS, SOCIAL_LINKS} from "../../lib/constants";
import logo from '../../../assets/img/paymentcomponents-logo-white.png';

const FooterComponent = (props) =>  {
    const {location} = props;
    const [pathName, setPathName] = useState(null);
    const [pc14Open, setPc14Open] = useState(false);
    const [otherSolutionsOpen, setOtherSolutionsOpen] = useState(false);
    const [contactUsOpen, setContactUsOpen] = useState(false);

    useEffect(()=> {
        if (location && location.pathname) {
            setPathName(location.pathname);
        }
    }, [location]);

    const renderRouteLink = (obj, idx) => {
        if (obj.type === 'link') {
            return <a href={obj.path}
                      key={'footer-link-' + idx}
                      rel="noopener noreferrer"
                      target="_blank">
                        {obj.title}
                    </a>
        } else {
            return <Link to={obj.stateFrom ? {pathname: obj.path, state: {from: pathName}} : obj.path}
                         key={'footer-route-' + idx}
            >
                        {obj.title}
                    </Link>
        }
    }

    const toggleCol = (idx)=> {
       setPc14Open(idx === 0 && !pc14Open);
       setOtherSolutionsOpen(idx === 2 && !otherSolutionsOpen);
       setContactUsOpen(idx === 4 && !contactUsOpen);
    }

    return (
    <footer className="footer" style={{minHeight: "240px"}}>
        <section className='footer__content'>
            <img src={logo} alt='payment components logo white' width='39' height='19' loading='lazy' />
            {FOOTER_LINKS.map((item, idx)=> {
                return <article className={'footer__block footer__block--' + idx}
                                key={'footer-block-'+ idx}>
                    {idx === 0 ?
                        <h5 onClick={()=> {toggleCol(idx)}}>
                            {<span>{item.title}</span>}
                        </h5>:
                        <h6 onClick={()=> {toggleCol(idx)}}>
                            {<span>{item.title}</span>}
                        </h6>}
                            <div className={'footer__block--links' +
                            ((pc14Open && idx === 0) ||
                            (otherSolutionsOpen && idx === 2) ? ' footer__block--open' : '')}>
                                <React.Fragment>
                                    {item.paths.map((path, indx)=> {
                                        return <div className='footer__col'
                                                    key={'footer-col-' + indx}>
                                                {
                                                    path.col.map((path, index)=> {
                                                        return renderRouteLink(path, index)
                                                    })
                                                }
                                                </div>
                                    })}
                                </React.Fragment>
                            </div>
                       </article>
            })}
            <article className='footer__block footer__block--contact'>
                <h6 onClick={()=> {toggleCol(4)}}>
                    {'Contact us'}
                </h6>
                <div className={'footer__block--mail-phone' + (contactUsOpen ? ' footer__block--open' : '')}>
                    <a href="mailto:finaplo@paymentcomponents.com?subject=About FINaplo Financial Messages&body=">
                        {'finaplo@paymentcomponents.com'}
                    </a>
                    <span>
                        <span>{' 14 Chatziantoniou Street, Marousi, 151 24, Athens, Greece'}</span>
                    </span>
                    <span>
                        {'+30 210 6145 050'}
                    </span>
                </div>
                <div className='footer__block--social'>
                        {SOCIAL_LINKS.map((link, index)=> {
                            return <a href={link.path}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      title={link.title}
                                      className={'social-icon social-icon__' + link.title }
                                      key={'footer-social-' + link.title + index}
                            >
                                <span>{link.title}</span>
                            </a>
                        })}
                </div>
            </article>
        </section>
    </footer>
    );
}

export const Footer = withRouter(FooterComponent);
